import React, { useState } from 'react';
import { FaCopy, FaTwitter, FaTelegram, FaDiscord } from 'react-icons/fa';
import './App.css';

const App: React.FC = () => {
  const [copySuccess, setCopySuccess] = useState('');
  const [activeSection, setActiveSection] = useState('home');
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const contractAddress = '3PZRQiDvAmY3oC4cyePxkvqUoamgAQg5J5UFGkCBpump';

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(contractAddress);
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      
      case 'about':
        return <div className="content">
        <div className="text-container">
          <h1>About, The Fer Story:</h1>
          <h3>Fer is two sides in one—a being of light hiding a mischievous shadow. Gentle and wise, he brings peace wherever he goes. But within, Fer harbors a secret: Luci, his demon alter ego, lurking in the shadows and waiting to cause chaos. With Fer, you find serenity… but the darkness of Luci is never far behind.</h3></div>
          </div>;

      case 'howToBuy':
        return <div className="content">

        <div id="integrated-terminal"></div>
        <div className="text-container">
          <h1>How to buy</h1>
          <h3>Details soon</h3></div>
          </div>;


      case 'socials':
        return <div className="content">
        <div className="text-container">
          <h1>Join the Inner Circle</h1>
          <a target="_blank" href="https://x.com/ferthecoin"><h4><FaTwitter /> X/Twitter</h4></a><br />
          <a target="_blank" href="https://t.me/ferthecoin"><h4><FaTelegram /> Telegram Community</h4></a>              
          </div>
          </div>;


      default:
        return <div><div className="title-container">
          <h1>Meet Fer, the light that hides the shadows.</h1>
          <h3>An angel with a demon within. Are you ready?</h3>
          
        </div>

        <div className="contract-container">
          <p className="title">CONTRACT ADDRESS (CA):</p>
          <div className="contract-address">
            <span>{contractAddress}</span>
            <FaCopy onClick={handleCopy} className="copy-icon" />
          </div>
          {copySuccess && <span className="copy-success">{copySuccess}</span>}
        </div></div>;
    }
  };

  return (
    <div className="app">
      <video className="background-video" autoPlay loop muted>
        <source src="video.mp4" type="video/mp4" />
      </video>
      <header className="navbar">
        <div className="navbar-logo">
          <a onClick={() => setActiveSection('default')}><img src="fer.svg" alt="Logo" /></a>
        </div>
        <nav className="navbar-links">
          <a href="#" onClick={() => setActiveSection('about')}>About Fer</a>
          <a href="https://memedepot.com/d/fer" target="_blank">Meme Depot</a>
          <a href="https://www.dextools.io/app/en/solana/pair-explorer/7UjfBMvpaTXgoLkcRE1ae3DE2AvRSKZLPcoWf8p9nQoc?t=1731500730859" target="_blank">Dexscreener</a>
          <a href="https://birdeye.so/token/3PZRQiDvAmY3oC4cyePxkvqUoamgAQg5J5UFGkCBpump/97X9vvqPfH1QWbZBsLg5AcKCNhAytAYfmQqN3rotduMw?chain=solana" target="_blank">Birdeye</a>
          <a href="#" onClick={() => setActiveSection('socials')}>Socials</a>
          <a href="https://x.com/ferthecoin" target="_blank"><FaTwitter /></a>
          <a href="https://t.me/ferthecoin" target="_blank"><FaTelegram /></a>
          <button className="buy-button"><a href="https://jup.ag/swap/SOL-3PZRQiDvAmY3oC4cyePxkvqUoamgAQg5J5UFGkCBpump" target="_blank">BUY</a></button>
        </nav>
        
        {/* Hamburger menu for mobile */}
        <div className="hamburger" onClick={() => setHamburgerOpen(!hamburgerOpen)}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        
        {/* Mobile links that appear when hamburger is open */}
        <nav className={`navbar-links-mobile ${hamburgerOpen ? 'show' : ''}`}>
          <a href="#" onClick={() => { setActiveSection('about'); setHamburgerOpen(false); }}>About Fer</a>
          <a href="https://memedepot.com/d/fer" target="_blank">Meme Depot</a>
          <a href="https://www.dextools.io/app/en/solana/pair-explorer/7UjfBMvpaTXgoLkcRE1ae3DE2AvRSKZLPcoWf8p9nQoc?t=1731500730859" target="_blank">Dexscreener</a>
          <a href="https://birdeye.so/token/3PZRQiDvAmY3oC4cyePxkvqUoamgAQg5J5UFGkCBpump/97X9vvqPfH1QWbZBsLg5AcKCNhAytAYfmQqN3rotduMw?chain=solana" target="_blank">Birdeye</a>
          <a href="#" onClick={() => { setActiveSection('socials'); setHamburgerOpen(false); }}>Socials</a>
          <a href="https://jup.ag/swap/SOL-3PZRQiDvAmY3oC4cyePxkvqUoamgAQg5J5UFGkCBpump" target="_blank">BUY</a>
        </nav>
      </header>
      
      <main className="content">
        {renderContent()} {/* Renders content based on activeSection */}
        
      </main>

      <footer className="footer">
        <p>© 2024 FER and all related logos, characters, names and distinctive likenesses thereof are exclusive property of FER. All Rights Reserved.</p>
        <p className="disclaimer">$FER is a coin with no real value. $FER serves no purpose other than entertainment. $FER doesn't have any value and people who buy it shouldn't expect any financial returns. When purchasing $FER you are agreeing that you have seen this disclaimer.</p>
      </footer>
    </div>
  );
};

export default App;